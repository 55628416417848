import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import styled from "styled-components";
import { userContext } from "../context/userContext";
import { auth, db } from "../firebase/firebase";
import CircularLoader from "../pages/CircularLoader";
import {
  FeatureBtn,
  FeatureText,
  BigFeatureBox,
  SmallFeatureBox,
  Card,
  SubText,
} from "../styles";
import CountUp from "react-countup";
import { useNavigate } from "react-router-dom";

const InfoSection = () => {
  const [loader, setLoader] = useState(false);
  const [user, loading] = useAuthState(auth);
  const [deposits, setDeposits] = useState([]);
  const [withdrawals, setWithdrawals] = useState([]);
  const { userData, currentPrices } = useContext(userContext);
  const navigate = useNavigate();

  function getTotalDeposits(deposits) {
    let DepositTotal = 0;
    for (let i = 0; i < deposits.length; i++) {
      const depositAmount =
        currentPrices[deposits[i].type] *
        deposits[i].amount *
        currentPrices.USD;
      DepositTotal = DepositTotal + depositAmount;
    }
    setDeposits(DepositTotal);
  }

  function getTotalWithdrawals(withdrawals) {
    let WithdrawalTotal = 0;
    for (let i = 0; i < withdrawals.length; i++) {
      let withdrawalAmount;
      if (withdrawals[i].type === "Crypto") {
        withdrawalAmount =
          currentPrices[withdrawals[i].asset] *
          withdrawals[i].amount *
          currentPrices.USD;
      } else {
        withdrawalAmount = currentPrices.USD * withdrawals[i].amount;
      }

      WithdrawalTotal = WithdrawalTotal + withdrawalAmount;
    }
    setWithdrawals(WithdrawalTotal);
  }

  useEffect(() => {
    const depositsList = [];
    const withdrawalList = [];
    async function getDeposits() {
      const q = query(
        collection(db, "deposits"),
        where("user", "==", user.uid)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        if (doc.data().status === "approved") {
          depositsList.push(doc.data());
        }
      });
      getTotalDeposits(depositsList);
    }

    async function getWithdrawals() {
      const q = query(
        collection(db, "withdrawals"),
        where("user", "==", user.uid)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        if (doc.data().status === "approved") {
          withdrawalList.push(doc.data());
        }
      });
      getTotalWithdrawals(withdrawalList);
    }

    if (!loading && user) {
      setLoader(false);
      getDeposits();
      getWithdrawals();
    }
  }, [user, loading]);

  return (
    <>
      {loader ? (
        <Wrapper>
          <CircularLoader />
        </Wrapper>
      ) : (
        <Wrapper>
          <Card className="card scrollbar-hidden">
            <SubText className="top">Account Summary</SubText>
            <ContentWrapper className="content">
              <SmallFeatureBox>
                <div className="left">
                  <FeatureBtn>
                    <img src="sidebaricons/deposit.svg" alt="deposits" />
                  </FeatureBtn>
                  <FeatureText>
                    <p className="topText">Total Deposits</p>
                    <CountUp
                      end={deposits}
                      duration={1}
                      delay={0}
                      separator=","
                      prefix="$"
                      decimals="2"
                    >
                      {({ countUpRef }) => (
                        <div>
                          <span className="bottomText" ref={countUpRef} />
                        </div>
                      )}
                    </CountUp>
                  </FeatureText>
                </div>

                <div style={{ backgroundColor: "transparent" }}>
                  <button
                    onClick={() => navigate("/deposit")}
                    className="actionButton"
                  >
                    <p className="actionText">Deposit</p>
                  </button>
                </div>
              </SmallFeatureBox>

              <SmallFeatureBox>
                <div className="left">
                  <FeatureBtn>
                    <img src="sidebaricons/withdrawal.svg" alt="withdrawals" />
                  </FeatureBtn>
                  <FeatureText>
                    <p className="topText">Total Withdrawals</p>
                    <CountUp
                      end={withdrawals}
                      duration={1}
                      delay={0}
                      separator=","
                      prefix="$"
                      decimals="2"
                    >
                      {({ countUpRef }) => (
                        <div>
                          <span className="bottomText" ref={countUpRef} />
                        </div>
                      )}
                    </CountUp>
                  </FeatureText>
                </div>

                <div style={{ backgroundColor: "transparent" }}>
                  <button
                    onClick={() => navigate("/withdrawal")}
                    className="actionButton"
                  >
                    <p className="actionText">New</p>
                  </button>
                </div>
              </SmallFeatureBox>

              <BigFeatureBox className="big" style={{ paddingBottom: "1rem" }}>
                <div className="left">
                  <FeatureBtn>
                    <img src="/sidebaricons/verify.svg" alt="verification" />
                  </FeatureBtn>
                  <FeatureText>
                    <p className="topText">Verification</p>

                    {userData?.verified === true && (
                      <p
                        className="bottomText blue"
                        style={{ fontSize: "16px" }}
                      >
                        Your account is verified.
                      </p>
                    )}
                    {userData?.verified === false && (
                      <p
                        className="bottomText red"
                        style={{ fontSize: "16px" }}
                      >
                        Your account is not verified.
                      </p>
                    )}
                  </FeatureText>
                </div>

                <div>
                  {userData?.verified === false && (
                    <button
                      className="actionButton"
                      onClick={() => navigate("/verify")}
                    >
                      <p className="actionText">Verify</p>
                    </button>
                  )}
                  {userData?.verified === true && (
                    <button className="actionButton" disabled>
                      <img
                        className="animate-pulse"
                        src="/sidebaricons/verify.svg"
                        style={{ padding: "0.2rem" }}
                        alt="verified"
                      />
                    </button>
                  )}
                </div>
              </BigFeatureBox>
            </ContentWrapper>
          </Card>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: grid;
  box-sizing: border-box;
  padding: 0 0.5rem;
  text-align: left;
  width: 100%;
  height: max-content;
  overflow: hidden;
  justify-self: flex-end;

  .card {
    box-sizing: border-box;
    height: max-content;
    overflow-y: auto;

    .top {
      position: sticky;
      z-index: 20;
      top: 0;
    }

    .content {
      /* height: 100%; */
      padding: 0.5rem;
      box-sizing: border-box;
      width: 100%;
      display: grid;
      gap: 1rem;
    }
  }
`;

const ContentWrapper = styled.div`
  /* box-sizing: border-box;
  height: 100%;
  max-height: 500px;
  grid-template-rows: 97px 97px auto;
  overflow-y: auto;
  width: 100%;

  .big {
    height: 100%;
  }

  div {
    box-sizing: border-box; */
  /* } */
`;

export default InfoSection;
