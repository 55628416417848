import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Card, DetailBox, TitleText } from "../styles";
import { userContext } from "../context/userContext";
import axios from "axios";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase/firebase";
import {
  doc,
  increment,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import toast from "react-hot-toast";
import CountUp from "react-countup";
import EditPlanModal from "../modals/EditPlanModal";

const SubscribeCard = ({ planDetails }) => {
  const { accounts, currentPrices, userData } = useContext(userContext);
  const [amount, setAmount] = useState("");
  const [fiatAccount, setFiatAccount] = useState("");
  const [amountExceedError, setAmountExceedError] = useState(false);
  const [subscribeMinError, setSubscribeMinError] = useState(false);
  const [subscribeMaxError, setSubscribeMaxError] = useState(false);
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [user] = useAuthState(auth);
  const [id, setID] = useState("");

  const [editPlan, setEditPlan] = useState(false)

  const [plan, setPlan] = useState(undefined)

  useEffect(() => {
    if (accounts) {
      const { live } = accounts;
      if (live) {
        const { Fiat } = live;
        setFiatAccount(Fiat);
      }
    }
  }, [accounts]);

  useEffect(() => {
    if (amount) {
      if (amount < 1000) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }

      // if (amount > 100 && amount < planDetails.minimum) {
      //   setSubscribeMinError(true);
      // } else {
      //   setSubscribeMinError(false);
      // }

      // if (amount > planDetails.maximum) {
      //   setSubscribeMaxError(true);
      // } else {
      //   setSubscribeMaxError(false);
      // }

      // if (amount > fiatAccount.value) {
      //   setAmountExceedError(true);
      // } else {
      //   setAmountExceedError(false);
      // }
    }
  }, [amount, planDetails]);

  useEffect(() => {
    if (user) {
      setID(user.uid);
    }
  }, [user]);

  function handleAmountChange(e) {
    const value = e.target.value;
    if (value > 0) {
      setAmount(value);
    } else {
      setAmount("");
      setAmountExceedError(false);
      setSubscribeMaxError(false);
      setSubscribeMinError(false);
    }
  }

  function handleSub(name, roi, duration) {
    setIsSubscribing(true);
    // decrementAmount(name, roi, duration, id, amount);
    setSubscription(name, roi, duration, id, amount);
  }

  // Decrement fiat
  const decrementAmount = async (name, roi, duration, id, amount) => {
    const q = doc(db, "accounts", id);
    try {
      await updateDoc(q, {
        "live.Fiat.value": increment(-amount),
      }).then(() => {
        setSubscription(name, roi, duration, id, amount);
      });
    } catch (error) {
      console.log(error);
    }
  };

  async function setSubscription(name, roi, duration, id, amount) {
    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      id.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    await setDoc(doc(db, "subscriptions", str), {
      date: serverTimestamp(),
      ref: str,
      status: "open",
      plan: name,
      user: id,
      duration,
      daysRan: 0,
      amount: Number(amount),
      roi,
    })
      .then(() => {
        toast.success("Subscribed successfully.");
        setIsSubscribing(false);
      })
      .catch((error) => {
        toast.error("Subscription request could not complete.");
      });
  }

  function handleEditPlan(details){
    setPlan(details)
    setEditPlan(true)
  }

  return (
    <Wrapper>

{editPlan && (
            <EditPlanModal
              open={{ editPlan, setEditPlan }}
              plan={plan}
            />
          )}

      <Card className="card">
        <TitleText className="top" style={{alignItems: "center"}}>
          <p>
          {planDetails.name}
          </p>
          
          
          {userData.admin && (
          <button className="actionButton" onClick={() => handleEditPlan(planDetails)}>
          <p className="actionText">Edit</p>
          </button> 
          )}
        
        </TitleText>
        <div className="content">
          <div className="title">
            <p className="infoTitle">Minimum</p>

            <CountUp
            end={planDetails.minimum}
            duration={1}
            delay={0}
            separator=","
            prefix="$"
          >
            {({ countUpRef }) => (
              <div>
                <p className="infoText" ref={countUpRef} />
              </div>
            )}
          </CountUp>

            {/* <p className="infoText">${planDetails.minimum}</p> */}
          </div>

          <div className="detailContainer">
            <p>Details</p>
            <div className="details">
              {planDetails.details.map((detail) => (
                <DetailBox key={detail.name}>
                  <p className="detailTitle">{detail.name}</p>
                  <p className="detailDesc">{detail.value}</p>
                </DetailBox>
              ))}
            </div>
          </div>

          <div className="amWrap">
            <label htmlFor="amount">Amount</label>
            <div className="amBx">
              <div className="amTop">
                <input
                  placeholder="1000"
                  name="amount"
                  onChange={handleAmountChange}
                  type="number"
                />
              </div>

              {/* <div className="captions">
                {amount && (
                  <p className="balance">
                    Current {fiatAccount.asset} balance - {""}
                    <span>
                      {fiatAccount.value} {fiatAccount.asset}
                    </span>
                  </p>
                )}
                {amount && (
                  <p className="extra">
                    USD
                    <span className="green">
                      {" "}
                      ~${currentPrices[fiatAccount.asset] * amount}
                    </span>
                  </p>
                )}
              </div> */}

              {amountExceedError && (
                <p
                  className="red warning textMedium"
                  style={{ marginTop: "1rem" }}
                >
                  Amount value cannot exceed current balance.
                </p>
              )}

              {subscribeMinError && (
                <p
                  className="red warning textMedium"
                  style={{ marginTop: "1rem" }}
                >
                  The minimum for this plan is {planDetails.minimum}
                </p>
              )}

              {subscribeMaxError && (
                <p
                  className="red warning textMedium"
                  style={{ marginTop: "1rem" }}
                >
                  The maximum for this plan is {planDetails.maximum}
                </p>
              )}
            </div>
          </div>

          <button
          style={{display: "grid", placeContent: "center"}}
            onClick={() =>
              handleSub(planDetails.name, planDetails.roi, planDetails.duration)
            }
            className={
              subscribeMaxError ||
              subscribeMinError ||
              amountExceedError ||
              !amount ||
              isDisabled ||
              isSubscribing
                ? "button disabled"
                : "button submit"
            }
            disabled={
              subscribeMaxError ||
              subscribeMinError ||
              isDisabled ||
              amountExceedError ||
              !amount ||
              isSubscribing
            }
          >
            {isSubscribing ? (
              <img
                src="/svg-loaders/tail-spin.svg"
                alt="loading"
                width={24}
                height={24}
              />
            ) : (
              <p>Subscribe</p>
            )}
          </button>
        </div>
      </Card>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: max-content;
  display: flex;
  max-width: 360px;

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding: 1rem;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .card {
    background: #131824;
  }

  .infoTitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: rgba(255, 255, 255, 0.8);
  }

  .infoText {
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    color: #ffffff;
  }

  .content {
    padding: 1rem;
    display: grid;
    gap: 40px;
  }

  .detailContainer {
    display: grid;
    gap: 0.25rem;
  }

  .details {
    display: flex;
    gap: 0.55rem;
    box-sizing: border-box;
    flex-wrap: wrap;
  }
`;

export default SubscribeCard;
