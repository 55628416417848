import {
  doc,
  increment,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { useContext, useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { userContext } from "../../context/userContext";
import { auth, db } from "../../firebase/firebase";
import { OutlinedBox } from "../../styles";

// action
// asset
// amount

const Stocks = ({ action, assets }) => {


  const { accounts, currentPrices, currentAccount } = useContext(userContext);
  const [asset, setAsset] = useState("AAPL");
  const [assetPrice, setAssetPrice] = useState(currentPrices[asset]);
  const [stockAccounts, setStockAccounts] = useState([]);
  const [fiatAccount, setFiatAccount] = useState([]);
  const [balance, setBalance] = useState("");
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [user] = useAuthState(auth);
  const [id, setID] = useState("");
  const [liveAccount, setLiveAccount] = useState([]);
  const [practiceAccount, setPracticeAccount] = useState([]);
  // const [accounts, setAccounts] = useState([]);
  const [time, setTime]  = useState(5)
  const [tp, setTP] = useState("");
  const [sl, setSL] = useState("");
  const [tpBuyError, setTPBuyError] = useState(false);
  const [tpSellError, setTPSellError] = useState(false);
  const [slBuyError, setSLBuyError] = useState(false);
  const [slSellError, setSLSellError] = useState(false);
  const [isAdvanced, setIsAdvanced] = useState(false)
  const tpRef = useRef();
  const slRef = useRef();

  const entryRef = useRef();

  const [entry, setEntry] = useState(assetPrice)

  useEffect(() => {
    if (accounts) {
      const { live, practice } = accounts;
      if (live && practice) {
        setLiveAccount(live);
        setPracticeAccount(practice);
      }
    } else {
      return;
    }
  }, [accounts]);

  useEffect(() => {
    if (currentAccount && liveAccount && practiceAccount) {
      switch (currentAccount) {
        case "live":
          setStockAccounts(liveAccount.Stock);
          setFiatAccount(liveAccount.Fiat);
          break;
        case "practice":
          setStockAccounts(practiceAccount.Stock);
          setFiatAccount(practiceAccount.Fiat);
          break;
        default:
          break;
      }
    }
  }, [currentAccount, liveAccount, practiceAccount]);

  useEffect(() => {
    if (user) {
      setID(user.uid);
    }
  }, [user]);

  function handleAssetChange(e) {
    const value = e.target.value;

    assets.forEach(acc => {
      if(acc.name === value) {
        setAsset(acc.symbol);
      }
    })
  }

  useEffect(() => {
    if (asset) {
      setAssetPrice(currentPrices[asset]);
      setEntry(currentPrices[asset])
    }
  }, [asset]);

  function handleAmount(e) {
    const value = e.target.value;
    if (value > 0) {
      setAmount(value);
    } else {
      setAmount("");
    }

    if (value > balance) {
      setAmountError(true);
    } else {
      setAmountError(false);
    }
  }

  function handleAmount(e) {
    const value = e.target.value;
    if (value > 0) {
      setAmount(value);
    } else {
      setAmount("");
    }

    // if (value > balance) {
    //   setAmountError(true);
    // } else {
    //   setAmountError(false);
    // }
  }

  // function handleEntry(e) {
  //   const value = e.target.value;
  //   if (value > 0) {
  //     setEntry(value);
  //   } else {
  //     setEntry(assetPrice);
  //   }
  // }

  function handleTP(e) {
    const value = e.target.value;
    if (value > 0) {
      setTP(value);
    } else {
      setTP("");
    }

    // if (tp && action === "buy" && value <= assetPrice) {
    //   setTPBuyError(true);
    // } else {
    //   setTPBuyError(false);
    // }

    // if (tp && action === "sell" && value >= assetPrice) {
    //   setTPSellError(true);
    // } else {
    //   setTPSellError(false);
    // }
  }

  function handleSL(e) {
    const value = e.target.value;
    if (value > 0) {
      setSL(value);
    } else {
      setSL("");
    }

    // if (sl && action === "buy" && value >= assetPrice) {
    //   setSLBuyError(true);
    // } else {
    //   setSLBuyError(false);
    // }

    // if (sl && action === "sell" && value <= assetPrice) {
    //   setSLSellError(true);
    // } else {
    //   setSLSellError(false);
    // }
  }

  // function handleTimeInForce(e) {
  //   const value = e.target.value;
  //   // const timeSlice = value.slice(0, value.indexOf(" "));
  //   setTime(Number(value));
  // }

  function handleTimeInForce(e) {
    const value = e.target.value;
    const timeSlice = value.slice(0, value.indexOf(" ")); 

    if(value.includes('minute')) {
      setTime(Number(timeSlice))
    }
    if(value.includes('hour')) {
      setTime(Number(timeSlice) * 10)
    }
    if(value.includes('day')) {
      setTime(Number(timeSlice) * 10)
    }
    if(value.includes('week')) {
      setTime(Number(timeSlice) * 10)
    }
    // if(value.includes('month')) {}
    // if(value.includes('year')) {}

    
    // setTime(Number(value));
  }

  function reset() {
    setAmount("");
    setAmountError(false);
    setTPBuyError(false);
    setTPSellError(false);
    setSLBuyError(false);
    setSLSellError(false);

    amountRef.current.value = "";

    // if(entryRef) {
    //   entryRef.current.value = "";
    // }

    if (isAdvanced && slRef) {
      slRef.current.value = "";
    }

    if (isAdvanced && tpRef) {
      tpRef.current.value = "";
    }
  }

  useEffect(() => {
    if (asset && fiatAccount && stockAccounts) {
      if (action === "buy") {
        setBalance(fiatAccount?.value);
      }

      if (action === "sell") {
        setBalance(stockAccounts[asset]?.value);
      }
    }
  }, [fiatAccount, action, asset, balance, amount, stockAccounts]);

  const handleTrade = () => {
    setIsSubmitting(true);
    if(isAdvanced) {

      if(action === 'sell'){
        handleAdvancedSell()
      }

      if(action === 'buy'){
        handleAdvancedBuy()
      }

    } else {
      if (action === "sell") {
        handleStockSell();
      }
  
      if (action === "buy") {
        handleStockBuy();
      }
    }
  };

  function handleStockBuy() {
    const type = "Stock";
    const action = "Buy";
    const converted = Number(amount / currentPrices[asset]).toFixed(2);
    submitStockBuyOrder(
      asset,
      Number(amount),
      id,
      assetPrice,
      type,
      action,
      converted
    );
  }

  // Decrement fiat
  const decrementAmount = async (
    asset,
    amount,
    id,
    price,
    type,
    action,
    converted
  ) => {
    const q = doc(db, "accounts", id);
    const key = `${currentAccount}.Fiat.value`;
    try {
      await updateDoc(q, {
        [key]: increment(-Number(amount)),
      }).then(() => {
        incrementStock(asset, amount, id, price, type, action, converted);
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Increment stock
  const incrementStock = async (
    asset,
    amount,
    id,
    price,
    type,
    action,
    converted
  ) => {
    const q = doc(db, "accounts", id);
    const key = `${currentAccount}.Stock.${asset}.value`;
    try {
      await updateDoc(q, {
        [key]: increment(Number(converted)),
      }).then(() => {
        submitStockBuyOrder(asset, amount, id, price, type, action);
      });
    } catch (error) {
      console.log(error);
    }
  };

  //submit Stock Order Placement
  async function submitStockBuyOrder(asset, amount, id, price, type, action) {
    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      id.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "closed",
      user: id,
      amount,
      asset,
      info: {entry},
      price,
      action,
      type: "Stocks",
      details: [
        {
          name: "Action",
          value: action,
        },
        {
          name: "Entry Price",
          value: entry,
        },
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Amount",
          value: amount,
        },
      ],
    })
      .then(() => {
        toast.success("Buy Order Placed");
        reset();
        setIsSubmitting(false);
      })
      .catch((error) => {
        toast.error("Order Could Not Complete.");
      });
  }

  function handleStockSell() {
    // decrement crypto
    // increment fiat
    // set trade doc
    // send email
    const type = "Stock";
    const action = "Sell";
    const converted = Number(currentPrices[asset] * Number(amount).toFixed(2));
    submitStockSellOrder(
      asset,
      Number(amount),
      id,
      assetPrice,
      type,
      action,
      converted
    );
  }

  // Decrement stock
  const decrementStock = async (
    asset,
    amount,
    id,
    price,
    type,
    action,
    converted
  ) => {
    const q = doc(db, "accounts", id);
    const key = `${currentAccount}.Stock.${asset}.value`;
    try {
      await updateDoc(q, {
        [key]: increment(-Number(amount)),
      }).then(() => {
        incrementAmount(asset, amount, id, price, type, action, converted);
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Increment fiat
  const incrementAmount = async (
    asset,
    amount,
    id,
    price,
    type,
    action,
    converted
  ) => {
    const q = doc(db, "accounts", id);
    const key = `${currentAccount}.Fiat.value`;
    try {
      await updateDoc(q, {
        [key]: increment(Number(converted)),
      }).then(() => {
        submitStockSellOrder(asset, amount, id, price, type, action);
      });
    } catch (error) {
      console.log(error);
    }
  };

  //submit Crypto Order Placement
  async function submitStockSellOrder(asset, amount, id, price, type, action) {
    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      id.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "closed",
      type: "Stocks",
      user: id,
      amount,
      asset,
      price,
      info: {entry},
      action,
      details: [
        {
          name: "Action",
          value: action,
        },
        {
          name: "Entry Price",
          value: entry,
        },
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Amount",
          value: amount,
        },
      ],
    })
      .then(() => {
        toast.success("Sell Order Placed");
        reset();
        setIsSubmitting(false);
      })
      .catch((error) => {
        toast.error("Order Could Not Complete.");
      });
  }

  const amountRef = useRef();

  async function handleAdvancedBuy() {
    const q = doc(db, "accounts", id);
    const key = `${currentAccount}.Fiat.value`;
    try {
      await updateDoc(q, {
        [key]: increment(-Number(amount)),
      }).then(() => {
        submitTrade()
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function handleAdvancedSell() {
    const q = doc(db, "accounts", id);
    const key = `${currentAccount}.Stock.${asset}.value`;
    try {
      await updateDoc(q, {
        [key]: increment(-Number(amount)),
      }).then(() => {
        submitTrade()
      });
    } catch (error) {
      console.log(error);
    }

    
  }

    //submit Trade Advanced
    async function submitTrade() {
      const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
      const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);
  
      const str =
        id.substring(0, 4) + randomOne.toString() + randomTwo.toString();

        const details = {
          advanced: true,
          action,
          account: currentAccount,
          tp,
          sl,
          entry,
          asset,
          time,
          amount,

      }
  
      await setDoc(doc(db, "trades", str), {
        date: serverTimestamp(),
        ref: str,
        status: "open",
        type: "Stocks",
        user: id,
        amount,
        asset,
        price: assetPrice,
        action,
        info: details,
        details: [
          {
            name: "Action",
            value: action,
          },
          {
            name: "Entry Price",
            value: assetPrice,
          },
          {
            name: "Asset",
            value: asset,
          },
          {
            name: "Amount",
            value: amount,
          },
        ],
      })
        .then(() => {
         
          postTrade(id, str, details);
        })
        .catch((error) => {
          toast.error("Order Could Not Complete.");
        });
    }
  
    async function postTrade(user, str, details) {
      const url = "https://fin-http-production.up.railway.app/defimarketspro/stocks";
  
      const base = {
        user,
        ref: str,
        details,
      };
  
      const config = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(base),
      };
  
      await fetch(url, config)
        .then((response) => {
          if (response) {
            toast.success("Trade sucessfully placed.");
            setIsSubmitting(false);
            reset();
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }


    function handleEntry(e) {
      const value = e.target.value;
      if (value > 0) {
        setEntry(value);
      } else {
        setEntry(assetPrice);
      }
    }

  return (
    <Wrapper>
      <div className="selectWrap">
        <label htmlFor="pair">Asset</label>
        <div className="selectBox">
          <div className="selectTop">
            {/* <span> */}
            <select name="asset" onChange={handleAssetChange}>
              {assets.map((ass) => (
                <option key={ass.symbol}>{ass.name}</option>
              ))}
            </select>
            <img src="/extraicons/arrowdown.svg" alt="select" />
            {/* </span> */}
          </div>

          <div className="captions">
            <span></span>

            {assetPrice && (
              <p className="balance">
                Current Price{" "}
                <span className="green">~${Number(assetPrice).toFixed(2)}</span>
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="amWrap">
        <label htmlFor="amount">Lot size</label>
        <div className="amBx">
          <div className="amTop">
            <input
              placeholder="1000"
              name="amount"
              type="number"
              ref={amountRef}
              style={{ padding: "1rem" }}
              onChange={handleAmount}
            />
          </div>

          {/* <div className="captions">
            <p className="balance">
              {amount && (
                <span>
                  Current Balance {""}
                  <span className={balance > 1 ? "green" : "red"}>
                    ~
                    {balance > 0.01
                      ? Number(balance).toFixed(3)
                      : Number(balance).toFixed(0)}{" "}
                    {action === "buy" ? "USD" : asset}{" "}
                    {balance < 0.01 && (
                      <Link to="/deposit" className="red">
                        Deposit now
                      </Link>
                    )}
                  </span>
                </span>
              )}
            </p>
            {amount && (
              <>
                {action === "buy" && (
                  <p className="extra">
                    {asset} ~
                    <span className="green">
                      {Number(amount / currentPrices[asset]).toFixed(4)}
                    </span>
                  </p>
                )}

                {action === "sell" && (
                  <p className="extra">
                    USD ~
                    <span className="green">
                      ${(currentPrices[asset] * Number(amount)).toFixed(2)}
                    </span>
                  </p>
                )}
              </>
            )}
          </div> */}
          {amountError && (
            <p className="red warning textMedium" style={{ marginTop: "1rem" }}>
              Trade amount cannot exceed current balance.
            </p>
          )}
        </div>
      </div>

      <div className="amWrap">
        <label htmlFor="amount">Entry Price</label>
        <div className="amBx">
          <div className="amTop">
            <input
              placeholder={Number(assetPrice).toFixed(2)}
              defaultValue={Number(assetPrice).toFixed(2)}
              name="amount"
              type="number"
              style={{ padding: "1rem" }}
              onChange={handleEntry}
              ref={entryRef}
            />
          </div>
        </div>
      </div>

      <div
        className="clickable"
        style={{
          display: "flex",
          placeSelf: "end",
          alignItems: "center",
          gap: "0.3rem",
        }}
      >
        <input
          type="checkbox"
          style={{ accentColor: "#1199fa" }}
          onChange={() => setIsAdvanced(!isAdvanced)}
        />
        Advanced
      </div>

      {isAdvanced && (
        <div className="amWrap" style={{ transition: "all 300ms ease-in" }}>
          <label htmlFor="takeProfit">Take Profit</label>
          <div className="amBx">
            <div className="amTop">
              <input
                placeholder={assetPrice + 2}
                name="takeProfit"
                type="number"
                ref={tpRef}
                style={{ padding: "1rem" }}
                onChange={handleTP}
              />
            </div>
            {tpBuyError && (
              <p
                className="red warning textMedium"
                style={{ marginTop: "1rem" }}
              >
                Please place your TP above the current price.
              </p>
            )}

            {tpSellError && (
              <p
                className="red warning textMedium"
                style={{ marginTop: "1rem" }}
              >
                Please place your TP below the current price.
              </p>
            )}
          </div>
        </div>
      )}

{isAdvanced && (
        <div className="amWrap" style={{ transition: "all 300ms ease-in" }}>
          <label htmlFor="stopLoss">Stop Loss</label>
          <div className="amBx">
            <div className="amTop">
              <input
                ref={slRef}
                placeholder={assetPrice - 2}
                name="stopLoss"
                type="number"
                style={{ padding: "1rem" }}
                onChange={handleSL}
              />
            </div>

            {slBuyError && (
              <p
                className="red warning textMedium"
                style={{ marginTop: "1rem" }}
              >
                Please place your SL below the current price.
              </p>
            )}

            {slSellError && (
              <p
                className="red warning textMedium"
                style={{ marginTop: "1rem" }}
              >
                Please place your SL above the current price.
              </p>
            )}
          </div>
        </div>
      )}

<div className="selectWrap">
        <label htmlFor="timeinforce">Duration</label>
        <div className="selectBox">
          <div className="selectTop">
          <select name="timeinforce" onChange={handleTimeInForce}>
          <option>5 minutes</option>
              <option>10 minutes</option>
              <option>15 minutes</option>
              <option>30 minutes</option>
              <option>1 hour</option>
              <option>2 hours</option>
              <option>4 hours</option>
              <option>6 hours</option>
              <option>10 hours</option>
              <option>20 hours</option>
              <option>1 day</option>
              <option>2 days</option>
              <option>3 days</option>
              <option>4 days</option>
              <option>5 days</option>
              <option>6 days</option>
              <option>7 days</option>
              <option>1 week</option>
              <option>2 weeks</option>
              <option>3 weeks</option>
            </select>
            <img src="/extraicons/arrowdown.svg" alt="select" />
          </div>
        </div>
      </div>

      {/* <OutlinedBox style={{ padding: "0.5rem", margin: "0", width: "100%" }}>
        <span
          className="infot"
          style={{
            display: "flex",
            flexWrap: "wrap",
            maxWidth: "360px",
          }}
        >
          {isAdvanced
            ? " Your trade will auto close if SL or TP does not hit in " +
              time +
              " minutes."
            : "Your trade will auto close in " + time + " minutes."}
        </span>
      </OutlinedBox> */}
      

      <button
        disabled={!amount || !asset || !assetPrice}
        className={
          !amount || !asset || !assetPrice
            ? "button disabled"
            : "button submit"
        }
        onClick={handleTrade}
      >
        {isSubmitting ? (
          <img
            src="/svg-loaders/tail-spin.svg"
            alt="loading"
            width={24}
            height={24}
          />
        ) : (
          <span className="capitalize" style={{alignSelf: "center"}}>
            {"Place " + action + " Order"}
          </span>
        )}
      </button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  gap: 0.8rem;

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }
`;

export default Stocks;
